@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,800,900,1000,100italic,300italic,400italic,700italic,900italic');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,700,300,600,800,400');

body,
html {
    max-width: 54rem !important;
    margin: 0 auto !important;
    font-family: 'Lato', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #000000;
    font-size: 17px;

    @font-face {
        font-display: swap;
    }
}

@media (prefers-color-scheme: light) {
    :root {
        color-scheme: light;
        --hue-color: 243;
        --body-bg: #FFFFFF;
        --body-color: #0d1117;
        --head-text-color: #6c63ff;
        --card--bg: #FFFFFF;
        --card--text: #888888;
        --card--head: #0d1117;
        --dash--bg: #fff;
        --foot-text-color: black;
        --scroll-bar-color: hsl(var(--hue-color), 12%, 100%);
        --scroll-thumb-color: hsl(var(--hue-color), 12%, 90%);
        --scroll-thumb-hover-color: hsl(var(--hue-color), 12%, 80%);
        --text-var: #0d1117;
    }

    a:hover {
        color: #6c63ff;
    }

    .prbt {
        background-color: #fff;
        border-color: #6c63ff;
        color: black;
    }

    .prbt:hover {
        background-color: #6c63ff;
        border-color: #6c63ff;
        color: white;
    }

    .text-var {
        color: var(--text-var);
    }

}


/* Dark mode */

@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: dark;
        --hue-color: 243;
        --body-bg: #0d1117;
        --body-color: #FFFFFF;
        --head-text-color: #ffffff;
        --card--bg: #0d1117;
        --card--text: #FFFFFF;
        --card--head: #FFFFFF;
        --dash--bg: #0d1117;
        --foot-text-color: #ffffff;
        --scroll-bar-color: #0d1117;
        --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
        --scroll-thumb-hover-color: hsl(var(--hue-color), 12%, 26%);
        --text-var: #FFFFFF;
    }

    a:hover {
        color: #6c63ff;
    }

    .cdin {
        text-decoration-color: white;
    }

    .navx {
        color: #fff;
    }

    .prbt {
        background-color: #1a1a1a;
        border-color: #6c63ff;
        color: white;
    }

    .prbt:hover {
        background-color: #6c63ff;
        border-color: #6c63ff;
        color: white;
    }

    /* srch {
        background-color: #1a1a1a;
        color: white;
    } */

    .text-var {
        color: var(--text-var);
    }
}

.cdx {
    background-color: var(--card--bg);
    color: var(--card--text);
}

.cdt {
    color: var(--card--head);
}

.head {
    color: var(--head-text-color);
}

.cdin {
    background-color: var(--dash--bg);
}

.foot {
    color: var(--foot-text-color);
}

.cdb {
    background-color: var(--body-bg);
    color: var(--body-color);
}

body {
    background: var(--body-bg);
    color: var(--body-color);
}

.ufo {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

h1 {
    text-transform: uppercase;
}

h1 strong {
    font-weight: 900;
}

h2 {
    text-transform: uppercase;
}

h3 {
    font-size: 18px;
    font-weight: 900;
}

h4 {
    text-transform: uppercase;
}

h5 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
}

h6 {
    font-weight: bold;
    line-height: 20px;
}

p {
    font-family: 'Open Sans', sans-serif;
}

p.intro {
    font-size: 16px;
    margin: 12px 0 0;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
}

.row {
    margin-bottom: 20px;
}

.card-footer {
    background: transparent;
    border-top: 0px;
}

.loader {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    min-height: 80vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (min-width: 768px) {
    .news-input {
        width: 50%;
    }
}

@media screen and (max-width: 2700px) {
    .mainc {
        margin-left: 50px;
        margin-right: 50px;
    }

}


@media screen and (max-width: 1565px) {
    .body {
        font-weight: 400;
    }

    .mainc {
        margin-left: 50px;
        margin-right: 50px;
    }

    .navx {
        padding-left: 50px;
        padding-right: 50px;
    }

    .heading {
        font-size: 4.5rem !important;
    }

}

@media screen and (max-width: 768px) {
    .body {
        font-weight: 400;
    }

    .mainc {
        margin-left: 0px;
        margin-right: 0px;
    }

    .heading {
        font-size: 3.5rem !important;
    }
}

@media screen and (max-width: 480px) {
    .body {
        font-weight: 400;
    }

    .mainc {
        margin-left: -15px;
        margin-right: -15px;
    }

    .heading {
        font-size: 3.5rem !important;
    }
}

.heading {
    font-size: 5.5rem;
}

.notes-link {
    text-decoration: none;
}

/* ::-webkit-scrollbar {
    width: .6rem;
    background-color: var(--scroll-bar-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--scroll-thumb-hover-color);
} */